import { useNavigate } from "react-router-dom";
import React from "react";
import order from "../../assets/newImages/modal.png";
import leaf from "../../assets/newImages/leaf.png";
import coriander from "../../assets/newImages/corindar.png"

interface Props {
  modalOpen: boolean;
  setModalOpen?: any;
  closeModal: () => void;
  children: JSX.Element;
  title?: string;
  width?: Number;
  mobileWidth?: Number;
}

const ModalContainer = ({
  modalOpen,
  setModalOpen,
  closeModal,
  width = 65,
  mobileWidth = 90,
  title,
  children,
}: Props) => {
  return modalOpen ? (
    <div className="popup z-[10000] top-0 left-0 flex justify-center items-center fixed w-[100vw] min-h-[100vh]">
      <div
        className="bg-[#00000040] cursor-pointer absolute w-[100%] h-[100%]"
        onClick={() => {
          closeModal();
        }}
      ></div>

      <div
        className={` sm:rounded-[60px] rounded-[30px] md:min-w-[600px] bg-[#efefef] relative  w-[${width}%] max-w-[${width}%] md:w-[${mobileWidth}%] md:max-w-[${mobileWidth}%] overflow-hidden`}
      >
        <div className="absolute right-0 mt-12">
          <img
            className="w-[90px] object-cover"
            src={leaf}
            alt=""
          />
        </div>
        <div className="relative flex items-center justify-center mt-8">
          <img className="w-[250px]" src={order} alt="" />

          <div className="header  absolute p-[20px]">
            <h2 className="text-[34px] font-bold">{title}</h2>
          </div>
          {/* <i
            className="bi bi-x-lg absolute right-[15px] top-[15px] cursor-pointer"
            onClick={() => closeModal()}
          ></i> */}

        </div>
        <div className="absolute bottom-0 sm:block hidden ">
          <img
            className="w-[55px] object-cover mb-12"
            src={coriander}
            alt=""
          />
        </div>
        <div className="content__box  sm:p-[20px] p-[10px] flex flex-col gap-[24px]">
          {children}
        </div>

      </div>
    </div>

  ) : null;
};

export default ModalContainer;
