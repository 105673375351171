import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
import { setCartData } from "../../redux/productCart/productCartSlice";
import { GoPlus } from "react-icons/go";


interface ProductCardInterface {
  item: any;
  stepChange: any;
}

export const ProductCard = ({ item, stepChange }: ProductCardInterface) => {
  const { cartData, selectedCategory } = useSelector(
    (state: RootState) => state.cart
  );

  const dispatch = useDispatch();

  return (
    <div
      key={item.product_id}
      className="cursor-pointer mx-auto w-[100%] col-span-1 flex flex-col items-center justify-center rounded-[20px] md:p-[20px] p-[10px]"
      onClick={async () => {
        const cartItem = cartData.find(
          (cartItem: any) => cartItem.product_id === item.product_id
        );

        if (
          selectedCategory?.category_id < 5 &&
          cartData.find(
            (cartItem: any) => item.category_id === cartItem.category_id
          )
        ) {
          toast.error("You can only add one item from each category");
          return;
        }

        if (cartItem) {
          let newCartData = cartData.map((cartItem: any) => {
            if (cartItem.product_id === item.product_id) {
              return {
                ...cartItem,
                quantity: cartItem.quantity + 1,
              };
            }
            return cartItem;
          });

          dispatch(setCartData(newCartData));

          return;
        }
        const newCartData = [...cartData, { ...item, quantity: 1 }];
        await dispatch(setCartData(newCartData));
        stepChange();
      }}
    >
      <div className="shadow  border-[#CE2E64] border-[6px] rounded-[50%] md:w-[145px] w-[100px] h-[100px] overflow-hidden flex lg:h-[145px] md:h-[140px]  sm:mb-[-65px] mb-[-45px] z-[200] bg-[#fff]">
        <img
          src={item?.product_photo}
          alt=""
          className="object-cover w-[100%]"
        />
      </div>
      <div className="hover:bg-[#CE2E64] group transition-all hover:text-white shadow pb-[10px] lg:w-[260px] md:w-[235px] w-[180px] md:h-[300px] h-[220px] px-[26px] rounded-[35px] pt-[90px] bg-[#fff] flex flex-col text-center items-center justify-center">
        <h3 className="md:text-[28px] text-[20px] group-hover:text-white bebas-neue text-[#3B332D] pt-2 sm:pt-0">
          {item?.product_name}
        </h3>
        <div>
          <p className="lg:mt-[5px] text-[#8F8F8F] md:text-[16px] text-[10px] lg:mb-[10px] group-hover:text-white">
            {item?.product_desc}
          </p>
        </div>
        <div className="mt-auto flex flex-col sm:flex-row justify-between lg:gap-[24px] md:gap-2 items-center mb-[12px]">
          <div>
            <button className=" hidden text-[14px] sm:text-[16px] border sm:flex whitespace-nowrap items-center justify-between rounded-full group transition-all border-[#CE2E64] group-hover:bg-[#FDB604] group-hover:text-[#CE2E64] text-[#CE2E64] sm:px-[12px] sm:py-[6px]">
              <GoPlus className="lg:text-[22px] pr-[6px]" /> Add to cart
            </button>
          </div>
          <div className="sm:mt-6">
            <p className="md:text-[22px] text-[16px] font-bold text-[#3B332D] group-hover:text-white">
              {Number(item?.product_price - item?.discount) > 0 && (
                <>${Number(item?.product_price - item?.discount)?.toFixed(2)}</>
              )}
            </p>
            <div className="pb-6">
              <button className=" sm:hidden text-[14px] sm:text-[16px] border flex whitespace-nowrap items-center justify-between rounded-full group transition-all border-[#CE2E64] group-hover:bg-[#FDB604] group-hover:text-[#CE2E64] text-[#CE2E64] px-[8px] py-[4px]">
                <GoPlus className="sm:text-[22px] text-[20px] pr-[6px]" /> Add to cart
              </button>
            </div>
            <div className="group transition-all hover:text-white"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
