import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductLayout from "../../layouts/ProductLayout";
import { RootState } from "../../redux/store";
import { setSelectedCategory } from "../../redux/productCart/productCartSlice";
import { useSearchParams, useLocation } from "react-router-dom";
import ChooseCategoryModal from "./ChooseCategoryModal";
import { ProductCard } from "./ProductCard";
import curry from "../../assets/newImages/curry.png";
import leaf from "../../assets/newImages/leaf.png";

const ProductV2: React.FC = () => {
  const { productData, categoryData, curryData, buildCurry, selectedCategory } = useSelector((state: RootState) => state.cart);

  const dispatch = useDispatch();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [categoryShow, setCategoryShow] = useState<any[]>([]);
  const [isCategoryShowModalOpen, setIsCategoryShowModalOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCategory]);

  // Filter category to show
  useEffect(() => {
    if (categoryData?.length > 0) {
      const categoryParam = searchParams.get("category");
      const filteredCategories = categoryData?.filter((item: any) => 
        buildCurry ? item?.category_id < 5 : item?.category_id > 4
      );
      
      setCategoryShow(filteredCategories);
      
      if (categoryParam) {
        const selectedCat = filteredCategories.find((item: any) => item?.category_id === Number(categoryParam));
        if (selectedCat) {
          dispatch(setSelectedCategory(selectedCat));
        } else {
          dispatch(setSelectedCategory(filteredCategories[0]));
        }
      } else {
        dispatch(setSelectedCategory(filteredCategories[0]));
      }
    }
  }, [buildCurry, categoryData, searchParams]);

  const handleStepChange = () => {
    if (selectedCategory?.category_id < 9) {
      if (selectedCategory?.category_id === 4) {
        setIsCategoryShowModalOpen(true);
        searchParams.set("buildCurry", "false");
        setSearchParams(searchParams);
      }
      dispatch(
        setSelectedCategory(
          categoryData?.find(
            (item: any) =>
              item.category_id === Number(selectedCategory?.category_id) + 1
          )
        )
      );
    }
  };

  const pickAnotherCurryPlate = () => {
    searchParams.set("buildCurry", "true");
    setSearchParams(searchParams);
    const newCategory = categoryData?.find((item: any) => item.category_id === 1);
    if (newCategory) {
      dispatch(setSelectedCategory(newCategory));
    }
  };

  return (
    <ProductLayout>
      <>
        <div style={{ zIndex: 1000 }}>
          <ChooseCategoryModal
            isOpen={isCategoryShowModalOpen}
            onClose={() => setIsCategoryShowModalOpen(false)}
          />
        </div>
        <section
          ref={scrollRef}
          className="bg-[#f3f4f6] lg:px-[120px] mx-[14px] lg:py-[52px] md:pb-[110px] py-[32px] bg-cover object-contain"
          style={{ position: "relative", zIndex: 0 }}
        >
          <div className="top-0 absolute mt-[-210px] right-0 lg:mr-[880px] md:mr-[400px] lg:block md:block hidden">
            <img
              className="contrast-125 -rotate-90 lg:w-[200px] md:w-[120px]"
              src={leaf}
              alt=""
            />
          </div>
          <div className="container mx-auto flex flex-col items-center justify-center">
            <div className="sm:mt-[60px] mt-[34px] text-center justify-center items-center">
              <div className="capitalize">
                <div className="relative flex items-center justify-center">
                  <img
                    className="sm:w-[650px] object-cover"
                    src={curry}
                    alt=""
                  />
                  <h2 className="absolute md:text-[34px] text-[22px] font-bold">
                    {selectedCategory?.category_name}
                  </h2>
                </div>
                <p className="font-medium sm:text-xl text-[12px] text-center text-[#CE2E64] opacity-[75%] h-[10px] mt-[10px]">
                  {selectedCategory?.category_desc?.toUpperCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="col-span-3 w-[100%] flex items-center justify-center  ">
            {curryData?.length > 0 && selectedCategory?.category_id > 4 && (
              <p
                className="text-[white] border-[2px] hover:bg-transparent hover:border-[#CE2E64] hover:text-[#3b332d] bg-[#CE2E64] px-[12px] py-[8px] rounded-[10px] cursor-pointer mt-[32px] bebas-neue"
                onClick={() => {
                  pickAnotherCurryPlate();
                }}
              >
                Pick Another Curry Plate
              </p>
            )}
          </div>
          {location.pathname === "/product" &&
            !searchParams.get("buildCurry") && (
              <div className="col-span-3 w-[100%] flex items-center justify-center ">
                <p
                  className="text-[white] border-[2px] hover:bg-transparent hover:border-[#CE2E64] hover:text-[#3b332d] bg-[green] px-[12px] py-[8px] rounded-[10px] cursor-pointer mt-[32px] bebas-neue"
                  onClick={() => {
                    pickAnotherCurryPlate();
                  }}
                >
                  Pick Curry Plate
                </p>
              </div>
            )}

          <div className="flex sm:gap-[34px]  overflow-auto sm:overflow-hidden sm:mx-[12px] px-[20px] justify-center mt-[32px]">
            {categoryShow?.map((item: any, index: number) => {
              return (
                <div
                  style={{ marginLeft: index === 0 ? "40px" : "" }}
                  key={item.category_id}
                  className={` flex flex-col items-center justify-center cursor-pointer bebas-neue ${
                    selectedCategory?.category_id === item.category_id
                      ? "bg-[#CE2E64] sm:px-2 sm:py-1 rounded-md text-center border-[var(--primary)] text-[white] bebas-neue "
                      : ""
                  }`}
                  onClick={() => {
                    if (item?.category_id > 4) {
                      dispatch(setSelectedCategory(item));
                    }
                  }}
                >
                  <div className="flex items-center justify-center"></div>
                  <div className="mt-2 pb-1 mx-2 whitespace-nowrap flex-shrink-0">
                    <p className="md:text-[20px] text-base">
                      {item?.category_title}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className={`pb-[36px] scroll-smooth mt-[20px] ${
              productData.length > 6 ? " overflow-y-auto no-scrollbar" : ""
            }`}
          >
            <div className="mt-[20px] lg:w-[70%] md:px-8 mx-[8px] lg:mx-auto grid md:grid-cols-3 grid-cols-2 md:gap-[52px] gap-[26px]">
              {[
                ...productData?.filter(
                  (item: any) =>
                    item.category_id === selectedCategory?.category_id
                ),
              ].map((item, i) => {
                return (
                  <ProductCard
                    key={i}
                    item={item}
                    stepChange={handleStepChange}
                  />
                );
              })}
            </div>
          </div>
          <div className="absolute right-0 z-[-10]">
            <img
              className="contrast-125 -rotate-360 sm:w-[180px] sm:mt-[-180px] mt-[-30px]"
              src={leaf}
              alt=""
            />
          </div>
        </section>
      </>
    </ProductLayout>
  );
};

export default ProductV2;
