import { configureStore } from "@reduxjs/toolkit";
import productCartReducer from "./productCart/productCartSlice";
import userInfoReducer from "./userInfo/userInfoSlice";

export const store = configureStore({
  reducer: {
    cart: productCartReducer,
    user: userInfoReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
