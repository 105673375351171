import Cart from "../components/Cart";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar/Navbar";
// import Topbar from "../components/Topbar/Topbar";

interface Props {
  children: React.ReactNode;
}

const DefaultLayout = ({ children }: Props) => {
  return (
    <div className="DefaultLayout ">
      <div className="z-[100]">
        <Navbar />
      </div>
      {children}
      <Footer />
      <Cart />
    </div>
  );
};

export default DefaultLayout;
