import React from "react";
import ModalContainer from "../../components/ModalContainer/ModalContainer";
import AnotherCurryPlate from "../../assets/images/itemCurry.png";
import Appetizer from "../../assets/newImages/onion-fritters.jpg";
import Tandoori from "../../assets/newImages/chicken_tandoori.jpg";
import ExtrasNSides from "../../assets/newImages/coriander_chutney.webp";
import Desserts from "../../assets/newImages/rasgulla (1).webp";
import DrinksNShakes from "../../assets/newImages/mango.jpg";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCategory } from "../../redux/productCart/productCartSlice";
import { useSearchParams } from "react-router-dom";
import { GoChevronRight } from "react-icons/go";
import leaf from "../../assets/newImages/leaf.png";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ChooseCategoryModal = ({ isOpen, onClose }: Props) => {
  const categoryData = useSelector(
    (state: RootState) => state.cart.categoryData
  );

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleCategoryClick = (category: number) => {
    const selectedCategory = categoryData?.find(
      (item: any) => item.category_id === category
    );

    if (category === 1) {
      searchParams.set("buildCurry", "true");
      setSearchParams(searchParams);
    }

    dispatch(setSelectedCategory(selectedCategory));
    onClose();
  };

  return (
    <ModalContainer
      title="ORDER"
      modalOpen={isOpen}
      closeModal={() => onClose()}
      width={40}
    >
      <div className="flex flex-col sm:px-[30px] py-8 max-h-[540px] overflow-hidden no-scrollbar overflow-y-scroll">
        <div className="relative grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 sm:gap-[20px] gap-[28px]  justify-between">
          {[
            { id: 1, img: AnotherCurryPlate, label: 'Another Curry Plate' },
            { id: 5, img: Appetizer, label: 'Appetizer' },
            { id: 6, img: Tandoori, label: 'Tandoori' },
            { id: 8, img: Desserts, label: 'Dessert' },
            { id: 9, img: DrinksNShakes, label: 'Drink & Shake' },
            { id: 7, img: ExtrasNSides, label: 'Extra & Sides' },
          ].map(({ id, img, label }) => (
            <div
              className="flex items-center m-auto justify-center flex-col cursor-pointer group "
              key={id}
              onClick={() => handleCategoryClick(id)}
            >
              <div className="">
                <div className="sm:w-[208px] w-[165px] h-[105px]  sm:h-24  bg-[#fff] rounded-t-full shadow group-hover:bg-[#FFC215]"></div>
                <div className="bg-[#fff] sm:w-52 sm:h-56  w-[165px] h-[150px] shadow group-hover:bg-[#FFC215]">
                  <div className="text-center sm:pt-[120px] pt-[60px] sm:text-[26px] text-[24px] bebas-neue ">
                    {label.toUpperCase()}
                  </div>
                </div>
              </div>
              <div className="border-[#CE2E64] border-[6px] shadow absolute md:w-[165px] w-[120px] h-[120px] rounded-full overflow-hidden flex lg:h-[165px] md:h-[150px] sm:mt-[-120px] mt-[-100px] z-50 bg-[#fff] ml-[2px] ">
                <img className="object-cover w-[100%]" src={img} alt={label} />
              </div>
              <div className="absolute sm:mt-[320px] mt-[250px] rounded-full bg-[#FFC215] sm:p-[12px] p-[10px] shadow group-hover:bg-[#009743]">
                <GoChevronRight className="text-[#fff] sm:w-[28px] w-[20px] h-[20px] sm:h-[28px] font-bold" />
              </div>
            </div>
          ))}
        </div>
        <div className="flex gap-[20px] mx-auto sm:mt-[52px] mt-[20px]">
          <button
            className="border outline-0 b rounded-[50px] border-[#CE2E64] py-[6px] px-[28px] text-[#CE2E64] font-600 w-[100%] mt-[10px] flex items-center gap-[10px] justify-center"
            onClick={() => onClose()}
          >
            Cancel
          </button>
        </div>
      </div>

      {/* <div className="flex flex-col sm:px-[30px] px-[10px] py-8  max-h-[540px] overflow-hidden no-scrollbar overflow-y-scroll">
        <div className="grid md:grid-cols-6 grid-cols-2 sm:gap-[20px] gap-[10px] justify-between">
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(1);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={AnotherCurryPlate} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Another Curry Plate</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(5);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={Appetizer} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Appetizer</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(6);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={Tandoori} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Tandoori</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(7);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={ExtrasNSides} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Extra & Sides</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(8);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={Desserts} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Dessert</p>
            </div>
          </div>
          <div
            className="flex items-center m-auto justify-center flex-col cursor-pointer"
            onClick={() => {
              handleCategoryClick(9);
            }}
          >
            <div className="rounded-[50%] mb-[-40px] z-[200] p-[10px] bg-[#fff]">
              <img src={DrinksNShakes} alt="" className="w-[120px]" />
            </div>
            <div className="pb-[20px] md:w-[200px] w-[160px] h-[120px] px-[30px] rounded-[20px] pt-[60px] bg-[var(--secondary)] hover:text-[#ffff] flex items-center justify-center hover:bg-[var(--primary)]">
              <p className="text-center">Drink & Shake</p>
            </div>
          </div>
        </div>
        <div className="flex gap-[20px] mx-auto sm:mt-[30px] mt-[20px]">
          <button
            className="border outline-0 b rounded-[50px] border-[#CE2E64] py-[6px] px-[28px] text-[#CE2E64] font-600 w-[100%] mt-[10px] flex items-center gap-[10px] justify-center"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
      </div> */}
    </ModalContainer>
  );
};

export default ChooseCategoryModal;
