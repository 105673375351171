import React from "react";
import logo from "../../assets/images/logo.png";
import { LOGIN } from "../../navigation/Constant";
import { Link, useNavigate } from "react-router-dom";
import footerbg from "../../assets/newImages/footerbg.png"
import footerlogo from "../../assets/newImages/footerlogo.png"
import cup from "../../assets/newImages/cup.png"
import facebook from "../../assets/newImages/facebook.png"
import instagram from "../../assets/newImages/insta.png"
import twitter from "../../assets/newImages/x.png"

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div
      className="sm:pb-[80px] pb-[100px] pt-[10px] sm:h-[500px] h-full"
      style={{
        backgroundImage: `url(${footerbg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
      }}
    >
      <div className="sm:mt-[80px] mt-[30px]">
        <div className="block sm:hidden col-span-1 sm:ml-[80px] ml-[100px]">
          <Link to="/">
            <img className="w-[180px] object-cover" src={footerlogo} alt="" />
          </Link>
        </div>
        <div>
          <img className="block sm:hidden object-cover" src={cup} alt="" />
        </div>
      </div>
      <div className="grid sm:grid-cols-4  gap-[20px] sm:text-left text-center justify-center">
        <div className="col-span-1 ml-[80px] lg:ml-[100px] md:ml-[30px] sm:block hidden">
          <Link to="/">
            <img className="w-[180px] object-cover" src={footerlogo} alt="" />
          </Link>
        </div>
        <div className="col-span-1">
          <h3 className="text-[#3B332D] font-bold lg:text-[26px] md:text-[24px] text-[24px]">COMPANY</h3>
          <p onClick={() => {
            navigate(LOGIN);
          }}
            className="lg:text-[20px] md:text-[18px] text-[18px] text-[#3B332D] cursor-pointer">Staff Login</p>
          <p className="lg:text-[20px] md:text-[18px] text-[18px] text-[#3B332D] ">Terms & Conditions</p>
          <p className="lg:text-[20px] md:text-[18px] text-[18px] text-[#3B332D]">Refund & Cancellation</p>
          <p className="lg:text-[20px] md:text-[18px] text-[18px] text-[#3B332D] ">Privacy Policy</p>
          <p className="lg:text-[20px] md:text-[18px] text-[18px] text-[#3B332D] ">Cookie Policy</p>
        </div>
        <div className="col-span-1 sm:block flex flex-col items-center justify-center">
          <div>
            <h3 className="text-[#3B332D] font-bold lg:text-[26px] md:text-[24px] text-[24px] md:whitespace-nowrap">OPENING HOURS</h3>
            <p className="text-[#78716C] lg:text-[20px] md:text-[18px] text-[18px] font-medium">MON - FRI</p>
            <p className="text-[#3B332D] lg:text-[20px] md:text-[18px] text-[18px]">7AM - 10PM</p>
          </div>
          <div className="">
            <p className="text-[#78716C] lg:text-[20px] md:text-[18px] text-[18px] font-medium">SATURDAY</p>
            <p className="text-[#3B332D] lg:text-[20px] md:text-[18px] text-[18px]">8AM - 10PM</p>
          </div>
          <div className="">
            <p className="text-[#78716C] lg:text-[20px] md:text-[18px] text-[18px] font-medium">SUNDAY</p>
            <p className="text-[#3B332D] lg:text-[20px] md:text-[18px] text-[18px]">8AM - 11PM</p>
          </div>
        </div>
        
        <div className="mx-[24px] sm:mx-0 md:ml-[22px] lg:ml-0">
          <h3 className="text-[#3B332D] font-bold lg:text-[26px] md:text-[24px] text-[24px]">LOCATION</h3>
          <p className="lg:text-[20px] md:text-[18px] text-[18px] text-[#3B332D]">25958 SOUTH KNOLLWOOD, CHESTERFIELD, MI 48051</p>
        </div>
      </div>
      <div className="flex sm:pt-[120px] sm:justify-between justify-center items-center mx-[80px] sm:mt-[-60px] sm:flex-row flex-col">
        <div className="text-[#3B332D] sm:text-[20px] text-[16px] whitespace-nowrap mt-4">
          © Copyright 2023 - CurryCook
        </div>
        <div className="flex gap-[20px] sm:pb-0 sm:mt-0 mt-[24px]">
          <img src={facebook} alt="Facebook" />
          <img src={instagram} alt="Instagram" />
          <img src={twitter} alt="Twitter" />
        </div>
      </div>
      {/* <div className="w-full">
        <div className="grid sm:grid-cols-4 grid-cols-1 sm:gap-[180px]  mt-[220px] sm:mx-[60px]">
          <div>
            <Link to="/">
              <img className="w-[180px] object-cover" src={footerlogo} alt="" />
            </Link>
          </div>
          <div>
            <h3 className="text-[#3B332D] font-bold text-[26px]">OPENING HOURS</h3>
            <p className="text-[#78716C] text-[26px] font-medium">MON - FRI</p>
            <p className="text-[#3B332D] text-[26px] bebas-neue">7AM - 10PM</p>
          </div>
          <div className="mt-[40px]">
            <p className="text-[#78716C] text-[26px] font-medium">SATURDAY</p>
            <p className="text-[#3B332D] text-[26px] bebas-neue">8AM - 10PM</p>
          </div>
          <div className="mt-[40px]">
            <p className="text-[#78716C] text-[26px] font-medium">SUNDAY</p>
            <p className="text-[#3B332D] text-[26px] bebas-neue">8AM - 11PM</p>
          </div>
          <div></div>
          <div className="whitespace-nowrap mt-[-160px] border-b-[2px]">
            <h3 className="text-[#3B332D] font-bold text-[26px]">LOCATION</h3>
            <p className="text-[#3B332D] text-[26px] font-medium">25958 SOUTH KNOLLWOOD, CHESTERFIELD, MI 48051</p>
          </div>
        </div>
        <div className="grid sm:grid-cols-4 grid-cols-1 gap-[120px] absolute mt-[440px] mx-[60px] items-center justify-center">
          <div>
            <img className="sm:h-[410px] object-cover" src={cup} alt="" />
          </div>
          <div>
            <h3 className="text-[#3B332D] font-bold text-[26px]">COMPANY</h3>
            <p className="text-[20px] text-[#3B332D]">About Us</p>
            <p className="text-[20px] text-[#3B332D]">Careers</p>
            <p onClick={() => {
              navigate(LOGIN);
            }}
              className="text-[20px] text-[#3B332D] cursor-pointer">Staff Login</p>
          </div>
          <div>
            <h3 className="text-[#3B332D] font-bold text-[26px]">CONTACT</h3>
            <p className="text-[20px] text-[#3B332D]">Help & Support</p>
            <p className="text-[20px] text-[#3B332D]">Partner With US</p>
            <p className="text-[20px] text-[#3B332D]">Ride With US</p>
          </div>
          <div>
            <h3 className="text-[#3B332D] font-bold text-[26px]">LEGAL</h3>
            <p className="text-[20px] text-[#3B332D]">Terms & Conditions</p>
            <p className="text-[20px] text-[#3B332D]">Refund & Cancellation</p>
            <p className="text-[20px] text-[#3B332D]">Privacy Policy</p>
            <p className="text-[20px] text-[#3B332D]">Cookie Policy</p>
          </div>
        </div>
        <div className="absolute mt-[800px] gap-[800px] flex justify-between items-center mx-[80px]">
          <div className="text-[#3B332D] text-[20px]">© Copyright 2023 - CurryCook</div>
          <div className="flex gap-[20px]">
            <img src={facebook} alt="" />
            <img src={instagram} alt="" />
            <img src={twitter} alt="" />
          </div>
        </div>
      </div> */}
      {/* <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 sm:gap-[100px] gap-[20px] sm:pt-[50px] pt-[30px] pb-[30px]">
          <div>
            <Link to="/">
              <img src={logo} alt="" className="w-[180px] block mx-auto" />
            </Link>
            <p className="text-[12px] text-[#fff]">
              Savor the Authenticity. Quick, Delicious, Indian
            </p>
          </div>
          <div className="">
            <div className=" text-[18px] font-[600]">Company</div>
            <div className="flex flex-col gap-y-[8px] text-Medium+/Paragraph/Large mt-[16px]">
              <div className="text-[14px] font-[400]">About us</div>
              <div className="text-[14px] font-[400]">Careers</div>
              <div
                className="text-[14px] font-[400] cursor-pointer"
                onClick={() => {
                  navigate(LOGIN);
                }}
              >
                Staff Login
              </div>
            </div>
          </div>
          <div className=" self-start">
            <div className="text-[18px] font-[600]">Contact</div>
            <div className="flex flex-col gap-y-[8px] text-Medium+/Paragraph/Large mt-[16px]">
              <div className="text-[14px] font-[400]">Help & Support</div>
              <div className="text-[14px] font-[400]">Partner with us</div>
              <div className="text-[14px] font-[400]">Ride with us</div>
            </div>
          </div>
          <div className=" self-start">
            <div className="text-[18px] font-[600]">Legal</div>
            <div className="flex flex-col gap-y-[8px] text-Medium+/Paragraph/Large mt-[16px]">
              <div className="text-[14px] font-[400]">Terms & Conditions</div>
              <div className="text-[14px] font-[400]">
                Refund & Cancellation
              </div>
              <div className="text-[14px] font-[400]">Privacy Policy</div>
              <div className="text-[14px] font-[400]">Cookie Policy</div>
            </div>
          </div>
        </div>
        <hr className="h-[1px] bg-[#333333] w-[100%]" />
        <div className="flex justify-between py-[20px]">
          <p className="text-[14px] text-[#fff]">Copyright 2023 - CurryCook</p>

          <div className="flex gap-x-[20px]"></div>
        </div> */}
      {/* </div> */}

    </div>
  );
};

export default Footer;
