import React from "react";

import { Route, Routes } from "react-router-dom";

import { LOGIN, ORDERS, PRODUCT, ROOT, CONTACT } from "./Constant";
import NotFound from "./NotFound";
import Home from "../pages/Home";

import Order from "../pages/orders/order";
import Login from "../pages/Login";
import ProductV2 from "../pages/ProductV2";
import ContactUs from "../pages/ContactUs/ContactUs";

export const RouterConfig = () => {


  return (
    <Routes>
      <Route path={ROOT} element={<Home />} />

      <Route path={PRODUCT} element={<ProductV2 />} />
      <Route path={ORDERS} element={<Order />} />
      <Route path={LOGIN} element={<Login />} />
      <Route path={CONTACT} element={<ContactUs />} />


      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
