import React, { useEffect } from "react";
import ModalContainer from "../ModalContainer/ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CommonApi } from "../../apis/commonApi";
import { RootState } from "../../redux/store";
import { EMAIL_REGEX, PHONE_REGEX } from "../../constants/regex";
import { setUserInfo } from "../../redux/userInfo/userInfoSlice";
import {
  setCartData,
  setCurryData,
} from "../../redux/productCart/productCartSlice";
import deleteicon from "../../assets/icons/deleteIcon.svg";
import { calculateTotalSum, calculateTotalDiscount, TotalTaxAmount } from "../../utils";

interface CheckoutModalProps {
  isOpen: any;
  onClose: () => void;
}

const CheckoutModal = ({ isOpen, onClose }: CheckoutModalProps) => {
  const { curryData, cartData, categoryData } = useSelector(
    (state: RootState) => state.cart
  );


  const userInfo = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  const [totalSum, setTotalSum] = React.useState(0);

  const Checkout = async (e: any) => {
    e.preventDefault();
    if (curryData.length == 0 && cartData.length == 0) {
      toast.error("Please add an item first");
      return;
    }

    if (!userInfo?.name) {
      toast.error("Please enter name");
      return;
    } else if (!userInfo?.phone) {
      toast.error("Please enter phone");
      return;
    } else if (!userInfo?.email) {
      toast.error("Please enter email");
      return;
    } else if (EMAIL_REGEX.test(userInfo?.email) == false) {
      toast.error("Please enter valid email");
      return;
    } else if (PHONE_REGEX.test(userInfo?.phone) == false) {
      toast.error("Please enter valid phone");
      return;
    }

    // setLoading(true);

    const cartCheckout = [...cartData];

    const orderData: any = {};

    if (curryData.length > 0) {
      curryData.forEach((item: any) => {
        item.forEach((item2: any) => {
          orderData[item2.product_id] = {
            product_id: item2.product_id,
            qty: item2.quantity,
            subtotal: Number(
              Number(item2.product_price) * Number(item2.quantity || 1)
            )?.toFixed(2),
          };
        });
      });
    }

    cartCheckout.forEach((item: any) => {
      orderData[item.product_id] = {
        product_id: item.product_id,
        qty: item.quantity,
        subtotal: Number(
          Number(item.product_price) * Number(item.quantity || 1)
        )?.toFixed(2),
      };
    });

    const orderObj = {
      order_data: orderData,
      discount: 0,
      taxes: 0,
      tips_amt: 0,
      sub_total_price: Number(totalSum),
      total_price: Number(
        Number(totalSum) + parseFloat(Number(totalSum * 0.06)?.toFixed(2))
      ),
      payment_category: "Debit Card",
      payment_status: "Paid",
      user_name: userInfo?.name,
      user_email: userInfo?.email,
      user_mobile_no: userInfo?.phone,
      order_status: "In Progress",
    };
    console.log(orderObj.total_price)
    try {
      // setLoading(true);
      const response = await CommonApi.postCheckout(orderObj);

      if (response.success) {
        toast.success(response.message);
        window.location.href = response.data.values;
      }
    } catch (err: any) {
      console.log(err);
      const errrMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errrMsg);
    }
  };

  useEffect(() => {
    setTotalSum(
      calculateTotalSum([...cartData, ...curryData.flat(Infinity)])?.toFixed(2)
    );
  }, [cartData, curryData]);

  return (
    <ModalContainer
      title="Checkout"
      modalOpen={isOpen}
      closeModal={onClose}
      width={80}
    >
      <div className="flex flex-col sm:px-[30px] max-h-[520px] overflow-hidden no-scrollbar overflow-y-scroll">
        <div className="grid md:grid-cols-2 grid-cols-1 sm:gap-[30px] gap-[10px] ">
          <div className="col-span-1 border p-4 rounded-lg">
            <div className="grid md:grid-cols-2 grid-cols-1 sm:gap-[10px]">
              <div className="flex flex-col sm:gap-[5px]">
                {/* <label className="text-[#000]/[0.7] text-[16px]">Name</label> */}
                <input
                  value={userInfo?.name}
                  onChange={(e) =>
                    dispatch(setUserInfo({ ...userInfo, name: e.target.value }))
                  }
                  placeholder="Enter Name"
                  className="sm:p-[10px] p-[6px] rounded-[4px] border border-gray-400 outline-none"
                />
              </div>
              <div className="flex flex-col sm:gap-[5px]">
                {/* <label className="text-[#000]/[0.7] text-[16px]">Email ID</label> */}
                <input
                  value={userInfo?.email}
                  onChange={(e) =>
                    dispatch(
                      setUserInfo({ ...userInfo, email: e.target.value })
                    )
                  }
                  placeholder="Enter Email ID"
                  className="sm:p-[10px] p-[6px] rounded-[4px] border border-gray-400 outline-none"
                />
              </div>
              <div className="flex flex-col sm:gap-[5px]">
                {/* <label className="text-[#000]/[0.7] text-[16px]">Phone Number</label> */}
                <input
                  type="number"
                  value={userInfo?.phone}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      dispatch(
                        setUserInfo({ ...userInfo, phone: e.target.value })
                      );
                    }
                  }}
                  placeholder="Enter Phone Number"
                  className="sm:p-[10px] p-[6px] rounded-[4px] border border-gray-400 outline-none"
                />
              </div>
            </div>
          </div>
          {(cartData.length > 0 || curryData?.length > 0) && (
            <div className="col-span-1 flex flex-col bg-[var(--secondary)] sm:p-[20px] p-[16px] rounded-[20px] max-h-[44vh] overflow-y-scroll sm:overflow-hidden">
              <div className="flex flex-col max-h-[30vh] overflow-y-scroll">
                {curryData?.length > 0 &&
                  Object.keys(curryData).length > 0 &&
                  Object.keys(curryData).map((item: any, i: any) => (
                    <>
                      <p className=" text-[#CE2E64] font-semibold flex justify-between items-center">
                        {" "}
                        Curry Plate {Number(item) + 1}
                        <div className="w-[30px] h-[30px] rounded-[50%] flex items-center justify-center bg-[#fff] ml-auto">
                          <img
                            src={deleteicon}
                            className="w-[18px] cursor-pointer"
                            alt=""
                            onClick={() => {
                              let newCurryData = [...curryData];
                              newCurryData.splice(item, 1);
                              dispatch(setCurryData(newCurryData));
                            }}
                          />
                        </div>
                      </p>
                      {curryData[item].map((item: any, i: any) => (
                        <div
                          className="sm:my-[2px]"
                          key={i}
                        >
                          <div className="">
                            <div className="ml-[10px] relative flex justify-between">
                              <div>
                                <h2 className="font-[500] flex gap-[6px] items-center">
                                  {item?.product_name}
                                  <p className="text-[#CE2E64] text-[12px] whitespace-nowrap">
                                    x {item?.quantity}
                                  </p>
                                </h2>
                              </div>
                              <div>
                                <p className="text-[14px] font-[500] text-[#CE2E64]">
                                  $
                                  {(
                                    Number(item?.product_price) * item?.quantity
                                  )?.toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ))}

                {categoryData?.map((item: any, i: any) => (
                  <>
                    {cartData?.filter(
                      (item2: any) => item2?.category_id === item?.category_id
                    ).length > 0 && (
                        <div className="flex flex-col">
                          <p className="text-[#CE2E64] flex justify-between items-center font-semibold">
                            {item?.category_name}
                          </p>

                          <div>
                            {cartData
                              ?.filter(
                                (item2: any) =>
                                  item2?.category_id === item?.category_id
                              )
                              ?.map((item3: any) => {
                                return (
                                  <div className="ml-[10px] relative flex items-center justify-between">
                                    <h2 className="font-[500] flex gap-[6px] items-center">
                                      {item3?.product_name}
                                      <p className="text-[#CE2E64] text-[12px] whitespace-nowrap">
                                        x {item3?.quantity}
                                      </p>
                                    </h2>
                                    <div className="flex flex-row flex-nowrap items-center gap-1">
                                      <p className="text-[14px] font-[500] text-[#CE2E64]">
                                        $
                                        {(
                                          Number(item3?.product_price) *
                                          item3?.quantity
                                        )?.toFixed(2)}
                                      </p>
                                      <div className="w-[30px] h-[30px] rounded-[50%] flex items-center justify-center bg-[#fff] ml-auto">
                                        <img
                                          src={deleteicon}
                                          className="w-[18px] cursor-pointer"
                                          alt=""
                                          onClick={() => {
                                            let newCartData = [...cartData];

                                            const productIndex =
                                              newCartData.findIndex(
                                                (item4: any) =>
                                                  item4.product_id ===
                                                  item3.product_id
                                              );
                                            if (
                                              newCartData[productIndex].quantity >
                                              1
                                            ) {
                                              newCartData = newCartData?.map(
                                                (item5: any, index: number) => {
                                                  if (index === productIndex) {
                                                    return {
                                                      ...item5,
                                                      quantity:
                                                        item5.quantity - 1,
                                                    };
                                                  }
                                                  return item5;
                                                }
                                              );
                                            } else {
                                              newCartData.splice(productIndex, 1);
                                            }

                                            dispatch(setCartData(newCartData));
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                  </>
                ))}
              </div>
              <div className="border-t-2 border-[#CE2E64] w-full mt-2"></div>
              <div className="flex  gap-[10px] items-center justify-between mt-auto">
                <p className="font-semibold">Sub Total</p>
                <p className="sm:text-[20px] text-[#CE2E64]">
                  $
                  {calculateTotalSum([
                    ...cartData,
                    ...curryData.flat(Infinity)]).toFixed(2)}
                </p>
              </div>
              <div className="flex sm:pt-[px] gap-[10px] items-center justify-between mt-auto">
                <p className="font-semibold">Total Discount</p>
                <p className="sm:text-[20px] text-[#CE2E64]">
                  -$
                  {calculateTotalDiscount([
                    ...cartData,
                    ...curryData.flat(Infinity)]).toFixed(2)}
                </p>
              </div>
              <div className="flex gap-[10px] items-center justify-between mt-auto">
                <p className="font-semibold">Tax 6%</p>
                <p className="sm:text-[20px] text-[#CE2E64]">
                  $
                  {(((TotalTaxAmount([
                    ...cartData,
                    ...curryData.flat(Infinity),
                  ])) * 0.06)).toFixed(2)}
                </p>
              </div>
              <div className="flex gap-[10px] items-center justify-between mt-auto">
                <p className=""><span className="font-semibold">Net Total</span></p>
                <p className="sm:text-[20px] text-[#CE2E64]">
                  $
                  {(TotalTaxAmount([
                    ...cartData,
                    ...curryData.flat(Infinity),
                  ]) + ((TotalTaxAmount([
                    ...cartData,
                    ...curryData.flat(Infinity),
                  ])) * 0.06)).toFixed(2)}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-[20px] sm:ml-auto sm:mt-[30px] mt-[8px]">
          <button
            className="border border-[#CE2E64] outline-0 b rounded-[10px] py-[10px] px-[30px] text-[#CE2E64] font-600 w-[100%] mt-[10px] flex items-center gap-[10px] justify-center"
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            className="border-0 outline-0 b rounded-[10px] bg-[#CE2E64] py-[10px] px-[30px] text-white font-600 w-[100%] mt-[10px] flex items-center gap-[10px] justify-center"
            onClick={(e) => {
              Checkout(e);
            }}
          >
            Pay
          </button>

        </div>
      </div>
    </ModalContainer>
  );
};

export default CheckoutModal;
