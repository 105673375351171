import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/curry logo.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setCartData, setIsCartOpen } from "../../redux/productCart/productCartSlice";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"; // Hamburger menu icons
import IncompleteCurryModal from "../Modal/IncompleteCurryModal";
import cart from "../../assets/newImages/cart.png";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isCartOpen = useSelector((state: RootState) => state.cart.isCartOpen);
  const handleCartOpen = () => {
    dispatch(setIsCartOpen(!isCartOpen));
  };

  const cartData = useSelector((state: RootState) => state.cart.cartData);
  const hasItemsInCart = cartData?.length > 0;

  const [showCartPopup, setShowCartPopup] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage the mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="sm:p-[15px] pt-[10px] mx-4 sm:mx-0">
      {/* <div className="container mx-auto flex md:flex-row flex-col sm:gap-[30px] gap-[5px] justify-between items-center">
        <div className="flex flex-wrap gap-[20px]">
          <Link to="/">
            <img src={Logo} className="brand sm:w-[150px] w-[100px] object-contain sm:object-fill" alt="" />
          </Link>
        </div>
        <div className="flex gap-[18px] items-center justify-center">
          <div className="flex gap-[20px]">
            <Link to="/product?buildCurry=true">
              <p className="text-black text-[18px] font-medium cursor-pointer  hover:text-white">
                Build Curry Plate
              </p>
            </Link>

            <p
              className="text-black text-[18px] font-medium cursor-pointer  hover:text-white"
              onClick={() => {
                if (
                  cartData?.filter?.((item: any) => item?.category_id < 4)
                    ?.length > 0
                ) {
                  setShowCartPopup(true);
                } else {
                  navigate("/product");
                }
              }}
            >
              Order menu
            </p>
          </div>
          <div
            onClick={handleCartOpen}
            onKeyDown={handleCartOpen}
            className="product-cart__header--close"
          >
            <ShoppingCartOutlined className={`text-[20px] mb-4 ${hasItemsInCart ? 'text-red-500' : 'text-black'}`} />
          </div>
        </div>
      </div> */}
      <div className="md:mx-[14px] lg:mx-[60px] mb-6 flex justify-between items-center">
        <div className="z-50">
          <Link to="/">
            <img src={Logo} className="object-cover sm:w-[150px] w-[110px]" alt="Logo" />
          </Link>
        </div>
        <div className="flex items-center z-50">
          <div className="hidden md:flex lg:flex gap-[20px] items-center">
            <Link to="/product?buildCurry=true">
              <p className="text-[20px] cursor-pointer bebas-neue hover:text-[#009743] text-black">
                Build Curry Plate
              </p>
            </Link>
            <p
              className="text-[20px] cursor-pointer bebas-neue hover:text-[#009743] text-black"
              onClick={() => {
                if (cartData?.filter((item: any) => item?.category_id < 4)?.length > 0) {
                  setShowCartPopup(true);
                } else {
                  navigate("/product");
                }
              }}
            >
              Order Menu
            </p>
            <Link to="/contact-us">
              <p className="text-[20px] cursor-pointer bebas-neue hover:text-[#009743] text-black">
                Contact Us
              </p>
            </Link>
          </div>
          <div
            onClick={handleCartOpen}
            onKeyDown={handleCartOpen}
            className="ml-4 cursor-pointer "
          >
            <img
              className={`sm:w-[40px] w-[35px] sm:mb-2 ${hasItemsInCart ? "text-red-500" : "text-black"}`}
              src={cart}
              alt="Cart"
            />
          </div>
          <div className="lg:hidden md:hidden ml-4 cursor-pointer" onClick={toggleMenu}>
            {isMenuOpen ? <AiOutlineClose className="text-[#CE2E64]" size={30} /> : <AiOutlineMenu className="text-[#CE2E64]" size={30} />}
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="lg:hidden my-4 flex flex-col gap-[20px] items-center">
          <Link to="/product?buildCurry=true" onClick={toggleMenu}>
            <p className="text-[20px] cursor-pointer bebas-neue hover:text-[#009743] text-black">
              Build Curry Plate
            </p>
          </Link>
          <p
            className="text-[20px] cursor-pointer bebas-neue hover:text-[#009743] text-black"
            onClick={() => {
              if (cartData?.filter((item: any) => item?.category_id < 4)?.length > 0) {
                setShowCartPopup(true);
              } else {
                navigate("/product");
              }
              toggleMenu();
            }}
          >
            Order Menu
          </p>
          <Link to="/contact-us" onClick={toggleMenu}>
            <p className="text-[20px] cursor-pointer bebas-neue hover:text-[#009743] text-black">
              Contact Us
            </p>
          </Link>
        </div>
      )}
      <IncompleteCurryModal
        isOpen={showCartPopup}
        onClose={() => setShowCartPopup(false)}
        onSubmit={() => {
          dispatch(setCartData(cartData?.filter((item: any) => item?.category_id > 4)));
          navigate("/product");
          setShowCartPopup(false);
        }}
      />
    </div>
  );
};

export default Navbar;
