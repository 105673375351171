import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: {
        name: "",
        email: "",
        phone: ""
    },
    reducers: {
        setUserInfo: (state, action: PayloadAction<any>) => {
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
            return state;
        }
    }
});

export const { setUserInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;